/* 
	Table of Contents

    01. Settings ----------------------------------
    02. Sprite ------------------------------------
    03. Generic -----------------------------------
    04. Base --------------------------------------
         Tablet Portrait --------------------------
         Mobile -----------------------------------
    05. Fonts -------------------------------------
    06. Helpers -----------------------------------
         Clear ------------------------------------
         Notext -----------------------------------
         Hidden -----------------------------------
         Alignleft --------------------------------
         Alignright -------------------------------
         Disabled ---------------------------------
         Grid -------------------------------------
         Responsive Helpers -----------------------
    07. Reset -------------------------------------
    08. Regions -----------------------------------
    09. Container ---------------------------------
    10. Content -----------------------------------
    11. Footer ------------------------------------
         Mobile -----------------------------------
    12. Header ------------------------------------
    13. Main --------------------------------------
    14. Main White --------------------------------
    15. Main No padding ---------------------------
    16. Shell -------------------------------------
    17. Sidebar -----------------------------------
    18. Wrapper -----------------------------------
    19. Modules -----------------------------------
    20. Bar ---------------------------------------
         Mobile -----------------------------------
    21. Breadcrumbs -------------------------------
         Mobile -----------------------------------
    22. Button ------------------------------------
         Mobile -----------------------------------
         Button Search ----------------------------
         Button Blue ------------------------------
         Button Gray ------------------------------
         Button Sort ------------------------------
         Button Block -----------------------------
         Button Expand ----------------------------
         Button More ------------------------------
         Mobile -----------------------------------
    23. Copyright ---------------------------------
    24. Credits -----------------------------------
         Mobile -----------------------------------
    25. Datepicker --------------------------------
         Mobile -----------------------------------
    26. Filter ------------------------------------
         Tablet Portrait --------------------------
         Mobile -----------------------------------
    27. Form Elements -----------------------------
         Field ------------------------------------
         Mobile -----------------------------------
         Checkbox ---------------------------------
         Checkbox Alt -----------------------------
         Tablet Portrait --------------------------
         Mobile -----------------------------------
         Select -----------------------------------
         Mobile -----------------------------------
         Select Post ------------------------------
         Mobile -----------------------------------
    28. Form --------------------------------------
         Mobile -----------------------------------
    29. icons -------------------------------------
    30. Intro -------------------------------------
         Mobile -----------------------------------
    31. Intro Post --------------------------------
         Mobile -----------------------------------
    32. Link --------------------------------------
    33. List --------------------------------------
         List Checkboxes --------------------------
         Tablet Portrait --------------------------
         List Checks ------------------------------
         Small Desktop ----------------------------
         Tablet Portrait --------------------------
         Mobile -----------------------------------
         List links -------------------------------
         Mobile -----------------------------------
    34. Logo --------------------------------------
         Mobile -----------------------------------
    35. Nav ---------------------------------------
         Tablet Portrait --------------------------
         Mobile -----------------------------------
    36. Pdf ---------------------------------------
         Mobile -----------------------------------
    37. Post --------------------------------------
         Mobile -----------------------------------
    38. Post Large --------------------------------
         Tablet Portrait --------------------------
         Mobile -----------------------------------
    39. Post Alt ----------------------------------
         Mobile -----------------------------------
    40. Section -----------------------------------
         Mobile -----------------------------------
    41. Section inkblue ---------------------------
    42. Section Gray ------------------------------
    43. Section Results ---------------------------
         Tablet Portrait --------------------------
    44. Secttion Details --------------------------
         Mobile -----------------------------------
    45. Services ----------------------------------
         Tablet Portrait --------------------------
         Mobile -----------------------------------
    46. Slider ------------------------------------
    47. Socials -----------------------------------
    48. Tabs --------------------------------------
         Mobile -----------------------------------
    49. Table -------------------------------------
    50. Widget ------------------------------------
         Tablet Portrait --------------------------
    51. Themes ------------------------------------
 */

 .select2-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    -webkit-user-select: none; }
  .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    -webkit-user-select: none; }
  .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      -o-text-overflow: ellipsis;
         text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
  .select2-container .select2-search--inline .select2-search__field {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0; }
  .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }
  .select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }
  .select2-results {
  display: block; }
  .select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }
  .select2-results__option {
  padding: 6px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }
  .select2-container--open .select2-dropdown {
  left: 0; }
  .select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }
  .select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }
  .select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }
  .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }
  .select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }
  .select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }
  .select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }
  .select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }
  .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }
  .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }
  .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }
  .select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }
  .select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }
  .select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }
  .select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-appearance: textfield; }
  .select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }
  .select2-container--default .select2-results__option[role=group] {
  padding: 0; }
  .select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }
  .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }
  .select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }
  .select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }
  .select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, white), to(#eeeeee));
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eeeeee), to(#cccccc));
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
  .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }
  .select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }
  .select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }
  .select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }
  .select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(50%, #eeeeee));
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eeeeee), to(white));
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }
  .select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }
  .select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }
  .select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }
  .select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }
  .select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }
  .select2-container--classic .select2-dropdown--above {
  border-bottom: none; }
  .select2-container--classic .select2-dropdown--below {
  border-top: none; }
  .select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }
  .select2-container--classic .select2-results__option[role=group] {
  padding: 0; }
  .select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }
  .select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }
  .select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }
  .select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }
  .date-picker{width:170px;height:25px;padding:0;border:0;line-height:25px;padding-left:10px;font-size:12px;font-family:Arial,sans-serif;font-weight:700;cursor:pointer;color:#303030;position:relative;z-index:2}
  .date-picker-wrapper{position:absolute;z-index:1;border:1px solid #bfbfbf;background-color:#efefef;padding:5px 12px;font-size:12px;line-height:20px;color:#aaa;font-family:Arial,sans-serif;-webkit-box-shadow:3px 3px 10px rgba(0,0,0,.5);box-shadow:3px 3px 10px rgba(0,0,0,.5);-webkit-box-sizing:initial;box-sizing:initial}
  .dp-clearfix{clear:both;height:0;font-size:0}
  .date-picker-wrapper.inline-wrapper{position:relative;-webkit-box-shadow:none;box-shadow:none;display:inline-block}
  .date-picker-wrapper.single-date{width:auto}
  .date-picker-wrapper.no-shortcuts{padding-bottom:12px}
  .date-picker-wrapper.no-topbar{padding-top:12px}
  .date-picker-wrapper .footer{font-size:11px;padding-top:3px}
  .date-picker-wrapper b{color:#666;font-weight:700}
  .date-picker-wrapper a{color:#6bb4d6;text-decoration:underline}
  .date-picker-wrapper .month-name{text-transform:uppercase}
  .date-picker-wrapper .select-wrapper{position:relative;overflow:hidden;display:inline-block;vertical-align:middle}
  .date-picker-wrapper .select-wrapper:hover{text-decoration:underline}
  .date-picker-wrapper .month-element{display:inline-block;vertical-align:middle}
  .date-picker-wrapper .select-wrapper select{position:absolute;margin:0;padding:0;left:0;top:-1px;font-size:inherit;font-style:inherit;font-weight:inherit;text-transform:inherit;color:inherit;cursor:pointer;-webkit-appearance:none;-moz-appearance:none;appearance:none;background:0 0;border:0;outline:0;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";filter:alpha(opacity=1);opacity:.01}
  .date-picker-wrapper .month-wrapper{border:1px solid #bfbfbf;border-radius:3px;background-color:#fff;padding:5px;cursor:default;position:relative;_overflow:hidden}
  .date-picker-wrapper .month-wrapper table{width:190px;float:left}
  .date-picker-wrapper .month-wrapper table.month2{width:190px;float:left}
  .date-picker-wrapper .month-wrapper table td,.date-picker-wrapper .month-wrapper table th{vertical-align:middle;text-align:center;line-height:14px;margin:0;padding:0}
  .date-picker-wrapper .month-wrapper table .day{padding:5px 0;line-height:1;font-size:12px;margin-bottom:1px;color:#ccc;cursor:default}
  .date-picker-wrapper .month-wrapper table div.day.lastMonth,.date-picker-wrapper .month-wrapper table div.day.nextMonth{color:#999;cursor:default}
  .date-picker-wrapper .month-wrapper table .day.checked{background-color:#9cdbf7}
  .date-picker-wrapper .month-wrapper table .week-name{height:20px;line-height:20px;font-weight:100;text-transform:uppercase}
  .date-picker-wrapper .month-wrapper table .day.has-tooltip{cursor:help!important}
  .date-picker-wrapper .month-wrapper table .day.has-tooltip .tooltip{white-space:nowrap}
  .date-picker-wrapper .time label{white-space:nowrap}
  .date-picker-wrapper .month-wrapper table .day.toMonth.valid{color:#333;cursor:pointer}
  .date-picker-wrapper .month-wrapper table .day.toMonth.hovering{background-color:#cdecfa}
  .date-picker-wrapper .month-wrapper table .day.lastMonth,.date-picker-wrapper .month-wrapper table .day.nextMonth{display:none}
  .date-picker-wrapper .month-wrapper table .day.real-today{background-color:#ffe684}
  .date-picker-wrapper .month-wrapper table .day.real-today.checked,.date-picker-wrapper .month-wrapper table .day.real-today.hovering{background-color:#70ccd5}
  .date-picker-wrapper table .caption{height:40px}
  .date-picker-wrapper table .caption>th:first-of-type,.date-picker-wrapper table .caption>th:last-of-type{width:27px}
  .date-picker-wrapper table .caption .next,.date-picker-wrapper table .caption .prev{padding:0 5px;cursor:pointer}
  .date-picker-wrapper table .caption .next:hover,.date-picker-wrapper table .caption .prev:hover{background-color:#ccc;color:#fff}
  .date-picker-wrapper .gap{position:relative;z-index:1;width:15px;height:100%;background-color:red;font-size:0;line-height:0;float:left;top:-5px;margin:0 10px -10px;visibility:hidden;height:0}
  .date-picker-wrapper .gap .gap-lines{height:100%;overflow:hidden}
  .date-picker-wrapper .gap .gap-line{height:15px;width:15px;position:relative}
  .date-picker-wrapper .gap .gap-line .gap-1{z-index:1;height:0;border-left:8px solid #fff;border-top:8px solid #eee;border-bottom:8px solid #eee}
  .date-picker-wrapper .gap .gap-line .gap-2{position:absolute;right:0;top:0;z-index:2;height:0;border-left:8px solid transparent;border-top:8px solid #fff}
  .date-picker-wrapper .gap .gap-line .gap-3{position:absolute;right:0;top:8px;z-index:2;height:0;border-left:8px solid transparent;border-bottom:8px solid #fff}
  .date-picker-wrapper .gap .gap-top-mask{width:6px;height:1px;position:absolute;top:-1px;left:1px;background-color:#eee;z-index:3}
  .date-picker-wrapper .gap .gap-bottom-mask{width:6px;height:1px;position:absolute;bottom:-1px;left:7px;background-color:#eee;z-index:3}
  .date-picker-wrapper .selected-days{display:none}
  .date-picker-wrapper .drp_top-bar{line-height:1.4;position:relative;padding:10px 40px 10px 0}
  .date-picker-wrapper .drp_top-bar .error-top,.date-picker-wrapper .drp_top-bar .normal-top{display:none}
  .date-picker-wrapper .drp_top-bar .default-top{display:block}
  .date-picker-wrapper .drp_top-bar.error .default-top{display:none}
  .date-picker-wrapper .drp_top-bar.error .error-top{display:block;color:red}
  .date-picker-wrapper .drp_top-bar.normal .default-top{display:none}
  .date-picker-wrapper .drp_top-bar.normal .normal-top{display:block}
  .date-picker-wrapper .drp_top-bar.normal .normal-top .selection-top{color:#333}
  .date-picker-wrapper .drp_top-bar .apply-btn{position:absolute;right:0;top:6px;padding:3px 5px;margin:0;font-size:12px;border-radius:4px;cursor:pointer;color:#d9eef7;border:solid 1px #0076a3;background:#0095cd;background:-moz-linear-gradient(top,#00adee,#0078a5);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');color:#fff;line-height:initial}
  .date-picker-wrapper .drp_top-bar .apply-btn.disabled{cursor:pointer;color:#606060;border:solid 1px #b7b7b7;background:#fff;background:-moz-linear-gradient(top,#fff,#ededed);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed')}
  .date-picker-wrapper .time{position:relative}
  .date-picker-wrapper.single-month .time{display:block}
  .date-picker-wrapper .time input[type=range]{vertical-align:middle;width:129px;padding:0;margin:0;height:20px}
  .date-picker-wrapper .time1{width:180px;padding:0 5px;text-align:center}
  .time2{width:180px;padding:0 5px;text-align:center}
  .date-picker-wrapper .time1{float:left}
  .date-picker-wrapper .time2{float:right}
  .date-picker-wrapper .hour{text-align:right}
  .minute{text-align:right}
  .date-picker-wrapper .hide{display:none}
  .date-picker-wrapper .first-date-selected,.date-picker-wrapper .last-date-selected{background-color:#49e!important;color:#fff!important}
  .date-picker-wrapper .date-range-length-tip{position:absolute;margin-top:-4px;margin-left:-8px;-webkit-box-shadow:0 0 3px rgba(0,0,0,.3);box-shadow:0 0 3px rgba(0,0,0,.3);display:none;background-color:#ff0;padding:0 6px;border-radius:2px;font-size:12px;line-height:16px;-webkit-filter:drop-shadow(0 0 3px rgba(0, 0, 0, .3));-moz-filter:drop-shadow(0 0 3px rgba(0, 0, 0, .3));-ms-filter:drop-shadow(0 0 3px rgba(0, 0, 0, .3));-o-filter:drop-shadow(0 0 3px rgba(0, 0, 0, .3));filter:drop-shadow(0 0 3px rgba(0, 0, 0, .3))}
  .date-picker-wrapper .date-range-length-tip:after{content:'';position:absolute;border-left:4px solid transparent;border-right:4px solid transparent;border-top:4px solid #ff0;left:50%;margin-left:-4px;bottom:-4px}
  .date-picker-wrapper.two-months.no-gap .month1 .next,.date-picker-wrapper.two-months.no-gap .month2 .prev{display:none}
  .date-picker-wrapper .week-number{padding:5px 0;line-height:1;font-size:12px;margin-bottom:1px;color:#999;cursor:pointer}
  .date-picker-wrapper .week-number.week-number-selected{color:#49e;font-weight:700}
  /* ------------------------------------------------------------ *\
    Settings
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Sprite
\* ------------------------------------------------------------ */
  .ico-arrow-down-dark { background-image: url(../images/sprite.png); background-position: 55.080213903743314% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
  .ico-arrow-down { background-image: url(../images/sprite.png); background-position: 58.82352941176471% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
  .ico-arrow-up { background-image: url(../images/sprite.png); background-position: 62.56684491978609% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
  .ico-check { background-image: url(../images/sprite.png); background-position: 51.46666666666667% 27.27272727272727%; background-size: 4266.666666666666% 2142.8571428571427%; width: 9px; height: 7px; display: inline-block; vertical-align: middle; font-size: 0px; }
  .ico-pie { background-image: url(../images/sprite.png); background-position: 47.2972972972973% 28.676470588235293%; background-size: 2742.8571428571427% 1071.4285714285713%; width: 14px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0px; }
  .ico-search { background-image: url(../images/sprite.png); background-position: 41.96185286103542% 29.32330827067669%; background-size: 2258.823529411765% 882.3529411764706%; width: 17px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0px; }
  .logo-image { background-image: url(../images/sprite.png); background-position: 0% 0%; background-size: 256% 100%; width: 150px; height: 150px; display: inline-block; vertical-align: middle; font-size: 0px; }
  .logo-text { background-image: url(../images/sprite.png); background-position: 100% 0%; background-size: 166.95652173913044% 428.57142857142856%; width: 230px; height: 35px; display: inline-block; vertical-align: middle; font-size: 0px; }
  @media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 192dpi) {
    .ico-arrow-down-dark { background-image: url(../images/sprite.@2x.png); background-position: 55.080213903743314% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
    .ico-arrow-down { background-image: url(../images/sprite.@2x.png); background-position: 58.82352941176471% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
    .ico-arrow-up { background-image: url(../images/sprite.@2x.png); background-position: 62.56684491978609% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
    .ico-check { background-image: url(../images/sprite.@2x.png); background-position: 51.46666666666667% 27.27272727272727%; background-size: 4266.666666666666% 2142.8571428571427%; width: 9px; height: 7px; display: inline-block; vertical-align: middle; font-size: 0px; }
    .ico-pie { background-image: url(../images/sprite.@2x.png); background-position: 47.2972972972973% 28.676470588235293%; background-size: 2742.8571428571427% 1071.4285714285713%; width: 14px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0px; }
    .ico-search { background-image: url(../images/sprite.@2x.png); background-position: 41.96185286103542% 29.32330827067669%; background-size: 2258.823529411765% 882.3529411764706%; width: 17px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0px; }
    .logo-image { background-image: url(../images/sprite.@2x.png); background-position: 0% 0%; background-size: 256% 100%; width: 150px; height: 150px; display: inline-block; vertical-align: middle; font-size: 0px; }
    .logo-text { background-image: url(../images/sprite.@2x.png); background-position: 100% 0%; background-size: 166.95652173913044% 428.57142857142856%; width: 230px; height: 35px; display: inline-block; vertical-align: middle; font-size: 0px; }
}
  /* ------------------------------------------------------------ *\
    Generic
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Base
\* ------------------------------------------------------------ */
  body { min-width: 320px; background: #fff; font-family: Montserrat, Arial, Helvetica, sans-serif; font-size: 15px; line-height: 1.0; color: #333; }
  a { color: inherit; text-decoration: underline; }
  a:hover,
a[href^="tel"] { text-decoration: none; }
  h1,
h2,
h3,
h4,
h5,
h6 { margin-bottom: 0.875em; }
  h1,
.h1 { font-size: 40px; font-weight:bolder;}
  h2,
.h2 { font-size: 22px; }
  h5,
.h5 { font-size: 16px; 
  line-height: 1.7;
}
  h6,
.h6 { font-size: 15px; }
  p,
ul,
ol,
dl,
hr,
table,
blockquote { margin-bottom: 1.75em; }
  h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child { margin-bottom: 0; }
  /* Tablet Portrait */
  @media (max-width: 1023px) {
    h1,
    .h1 { font-size: 27px; }
}
  /* Mobile */
  @media (max-width: 767px) {
    h1,
    .h1 { font-size: 20px; }	

    h2,
    .h2 { font-size: 18px; }

    h5,
    .h5 { font-size: 15px; }
}
  /* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Helpers
\* ------------------------------------------------------------ */
  /*  Clear  */
  .clear:after {
  content: '';
  display: block;
  clear: both; }
  /*  Notext  */
  .notext { overflow: hidden; text-indent: 101%; white-space: nowrap; }
  /*  Hidden  */
  [hidden],
.hidden { display: none !important; }
  /*  Alignleft  */
  .alignleft { float: left; }
  /*  Alignright  */
  .alignright { float: right; }
  /*  Disabled  */
  [disabled],
.disabled { cursor: default; }
  /*  Grid  */
  .cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-flow: row wrap; flex-flow: row wrap; }
  .col { max-width: 100%; -webkit-box-flex: 1; -ms-flex: 1 1; flex: 1 1; }
  .col--1of2 { max-width: 50%; -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; }
  /*  Responsive Helpers  */
  .visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block { display: none !important; }
  @media (max-width: 767px) {
    .hidden-xs { display: none !important; }
    .visible-xs-block { display: block !important; }
    .visible-xs-inline { display: inline !important; }
    .visible-xs-inline-block { display: inline-block !important; }
}
  @media (min-width: 768px) and (max-width: 1023px) {
    .hidden-sm { display: none !important; }
    .visible-sm-block { display: block !important; }
    .visible-sm-inline { display: inline !important; }
    .visible-sm-inline-block { display: inline-block !important; }
}
  @media (min-width: 1024px) and (max-width: 1200px) {
    .hidden-md { display: none !important; }
    .visible-md-block { display: block !important; }
    .visible-md-inline { display: inline !important; }
    .visible-md-inline-block { display: inline-block !important; }
}
  @media (min-width: 1201px) {
    .hidden-lg { display: none !important; }
    .visible-lg-block { display: block !important; }
    .visible-lg-inline { display: inline !important; }
    .visible-lg-inline-block { display: inline-block !important; }
}
  /* ------------------------------------------------------------ *\
    Reset
\* ------------------------------------------------------------ */
* { padding: 0; margin: 0; outline: 0; -webkit-box-sizing: border-box; box-sizing: border-box; }
  *:before,
*:after { -webkit-box-sizing: inherit; box-sizing: inherit; }
  article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main { display: block; }
  template { display: none; }
  html { -webkit-tap-highlight-color: rgba(0,0,0,0);  }
  body { -moz-osx-font-smoothing: grayscale; 
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    /* font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif; */
    /* font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif; */
    /* font-family: Arial,Helvetica,sans-serif; */
    -webkit-font-smoothing: antialiased; font-smoothing: antialiased; -webkit-text-size-adjust: none; -moz-text-size-adjust: none; -ms-text-size-adjust: none; text-size-adjust: none; }
  img,
iframe,
video,
audio,
object { max-width: 100%; }
  img,
iframe { border: 0 none; }
  img { height: auto; display: inline-block; vertical-align: middle; }
  b,
strong { font-weight: bold; }
  address { font-style: normal; }
  svg:not(:root) { overflow: hidden; }
  a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] { cursor: pointer; }
  a[href^="tel"],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] { cursor: default; }
  button::-moz-focus-inner,
input::-moz-focus-inner { padding: 0; border: 0; }
  input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] { -webkit-appearance: none; -moz-appearance: none; appearance: none; }
  input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; appearance: none; }
  textarea { overflow: auto; -webkit-overflow-scrolling: touch; overflow-scrolling: touch; }
  button,
input,
optgroup,
select,
textarea { font-family: inherit; font-size: inherit; color: inherit; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; }
  button,
select { text-transform: none; }
  table { width: 100%; border-collapse: collapse; border-spacing: 0; }
  nav ul,
nav ol { list-style: none outside none; }
  /* ------------------------------------------------------------ *\
    Regions
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Container
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Content
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Footer
\* ------------------------------------------------------------ */
  .footer { background-color: #000; color: #fff; padding: 133px 0 75px; position: relative; }
  .footer .footer__head { position: absolute; top: -74px; left: 0; right: 0; width: 100%; margin: auto; text-align: center; }
  .footer .footer__inner { max-width: 920px; margin: auto; }
  .footer .footer__nav ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -ms-flex-wrap: wrap; flex-wrap: wrap;}
  .footer .footer__nav li { padding: 0 15px 29px; }
  .footer .footer__nav a { font-size: 16px; text-decoration: none; }
  .footer .footer__nav a:hover { text-decoration: underline; }
  .footer .socials { margin-top: 13px; }
  .footer .footer__foot { text-align: center; margin-top: 37px; }
  .footer .footer__foot address { font-size: 14px; opacity: .8; margin-bottom: 0; }
  /* Mobile */
  @media (max-width: 767px) {
    .footer { padding: 55px 0 30px; }
    .footer .footer__head { top: -38px; }
    .footer .footer__nav li { padding: 0 10px 10px; }
    .footer .footer__nav a { font-size: 14px; }
    .footer .footer__foot { margin-top: 20px; }
    .footer .footer__foot address { font-size: 12px; }
}
  /* ------------------------------------------------------------ *\
    Header
\* ------------------------------------------------------------ */
  .header { background-color: #fff; }
  .header .header__inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding: 4px 0; }
  .header__link_item {
    color: #000000;
    font-size: 1.28571rem;
    font-weight: bold;
  }
  /* ------------------------------------------------------------ *\
    Main
\* ------------------------------------------------------------ */
  /* .main { padding-bottom: 54px; background-color: #e0e1e2; min-height: 600px;} */
  .main { padding-bottom: 54px; background-color: #000000; min-height: 600px;}
  .main__inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
  /* ------------------------------------------------------------ *\
    Main White
\* ------------------------------------------------------------ */
  .main--white { background-color: #fff; }
  /* ------------------------------------------------------------ *\
    Main No padding
\* ------------------------------------------------------------ */
  .main--no-padding { padding-bottom: 0; }
  /* ------------------------------------------------------------ *\
    Shell
\* ------------------------------------------------------------ */
  .shell { max-width: 1140px;padding:0px; margin: auto; }
  .shell--fluid { max-width: none; }

  @media (max-width: 1139px) {
    .shell {
      padding: 0 25px;
    }
  }
  @media (max-width: 767px) {
    .shell {
      max-width: unset;
      padding: 0 15px;
    }
  }
  /* ------------------------------------------------------------ *\
    Sidebar
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Wrapper
\* ------------------------------------------------------------ */
  .wrapper { min-height: 100vh; }
  /* ------------------------------------------------------------ *\
    Modules
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Bar
\* ------------------------------------------------------------ */
  .bar { padding: 22px 0; border-bottom: 1px solid #dddedf; }
  .bar .bar__content ul { list-style-type: unset; }
  .bar .bar__content li { display: block;margin-top: 15px; }
  
  /* Mobile */
  @media (max-width: 767px) {
    .bar { padding: 10px 0; }
    .bar .bar__content li { display: block;  }
    .bar .bar__content li + li { margin-left: 0; }
    
}
  /* ------------------------------------------------------------ *\
    Breadcrumbs
\* ------------------------------------------------------------ */
  .breadcrumbs ul { list-style-type: none; }
  .breadcrumbs li { display: inline-block; color: #fff; font-size: 13px; position: relative; }
  .breadcrumbs li + li { padding-left: 20px; margin-left: 15px; }
  .breadcrumbs li + li:after { content: ''; position: absolute; left: 0; top: 0; bottom: 0; background-image: url(../images/ico-arrow-right.png); background-size: 100% 100%; width: 6px; height: 10px; margin: auto; }
  .breadcrumbs li a { opacity: .99; }
  .breadcrumbs li a:hover{ opacity: 1.0; color: white;}
  /* Mobile */
  @media (max-width: 767px) {
    .breadcrumbs li { font-size: 10px; }
    .breadcrumbs li + li { padding-left: 15px; margin-left: 5px; }
    
}
  /* ------------------------------------------------------------ *\
    Button
\* ------------------------------------------------------------ */
  .btn { display: inline-block; opacity: 0.8;vertical-align: middle; text-align: center; cursor: pointer; text-decoration: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; color: #fff; padding: 12px 27px; font-size: 16px; letter-spacing: 0.025em; -webkit-transition: opacity .5s; -o-transition: opacity .5s; transition: opacity .5s; position: relative; }
  .btn:hover { opacity: 1.0; }
  .btn .arrows { display: inline-block; vertical-align: middle; position: absolute; top: 0; bottom: 0; right: 20px; margin: auto; width: 10px; height: 22px; }
  .btn .arrows .ico-arrow-up { position: absolute; top: 0; }
  .btn .arrows .ico-arrow-down-dark { position: absolute; bottom: 0; }
  /* Mobile */
  @media (max-width: 767px) {
    .btn { font-size: 14px; }
}
  /* Button Search */
  .btn--search span { padding-right: 10px; }
  .btn--search .ico-search { padding-right: 0; }
  /* Button Blue */
  .btn--blue { background-color: #1576D1; }
  /* Button Gray */
  .btn--gray { background-color: #e0e1e2b7; color: #333; }
  .btn--gray:hover { background-color:  #e0e1e2; color: #333; }
  /* Button Sort */
  .btn--sort { padding-right: 41px; }
  /*  Button Block  */
  .btn--block { display: block; padding-left: 0; padding-right: 0; }
  /* Button Expand */
  .btn-expand { font-size: 14px; color: #fff; opacity: 0.7 ;text-decoration: none; -webkit-transition: opacity 0.5s; -o-transition: opacity 0.5s; transition: opacity 0.5s; }
  .btn-expand span { padding-left: 7px; }
  .btn-expand .ico-arrow-down { padding-left: 0; }
  .btn-expand:hover { color : white;opacity: 1.0; }
  .btn-expand:focus { color : white;opacity: 1.0;}
  
  .btn-expand .hide { display: none; }
  .btn-expand.active .hide { display: inline-block; }
  .btn-expand.active .show { display: none; }
  /* Button More */
  .btn-more { display: block; background-color: #fff; border-bottom: 2px solid #1576D1; padding: 13px; text-align: center; color: #1576D1; font-size: 16px; text-decoration: none; -webkit-transition: background .3s; -o-transition: background .3s; transition: background .3s; }
  .btn-more:hover { background-color: #1576D1; color: #fff; }
  /* Mobile */
  @media (max-width: 767px) {
    .btn-more { padding: 7px; font-size: 14px; }
}
  /* ------------------------------------------------------------ *\
    Copyright
\* ------------------------------------------------------------ */
  .copyright { font-size: 12px; opacity: .8; margin-top: 10px; }
  /* ------------------------------------------------------------ *\
    Credits
\* ------------------------------------------------------------ */
  .credits { font-size: 14px; font-weight: 700; opacity: .6; margin-bottom: 0; }
  /* Mobile */
  @media (max-width: 767px) {
    .credits { font-size: 12px; }
}
  /* ------------------------------------------------------------ *\
    Datepicker
\* ------------------------------------------------------------ */
  .date-picker-wrapper { font-size: 15px; }
  .date-picker-wrapper .footer { padding: 0; }
  .date-picker-wrapper.no-topbar { padding: 0; }
  .date-picker-wrapper .month-wrapper table { width: 100%; }
  .date-picker-wrapper .month-wrapper table .day { padding: 15px; font-size: 15px; }
  /* Mobile */
  @media (max-width: 767px) {
    .date-picker-wrapper { font-size: 12px; }
    .date-picker-wrapper .month-wrapper table .day { font-size: 12px; padding: 10px; }
    
}
  /* ------------------------------------------------------------ *\
    Filter
\* ------------------------------------------------------------ */
  .filters { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding-left: 25px; }
  .filters .filters__head span { display: inline-block; vertical-align: middle; }
  .filters .filters__head svg { display: block; }
  .filters .filters__aside ul { list-style-type: none; }
  .filters .filters__aside ul li { display: inline-block; vertical-align: middle; font-size: 15px; }
  .filters .filters__aside ul li + li { margin-left: 5px; }
  .filters .filters__aside .btn { font-size: 15px; padding: 5px 20px;	}
  .filters .filters__aside .btn--sort { padding-right: 21px; }
  /* Tablet */
  @media (max-width: 1024px) {
    
  }

  /* Mobile */
  @media (max-width: 835px) {
    .filters { padding-left: 0; width: 100%; }
    .filters { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }	
    .filters .filters__aside { width: 100%; margin-top: 10px; }	
    .filters .filters__aside ul { text-align: center; }
    .filters .filters__aside ul .sort-title { display: block !important; }	
    .filters .filters__aside ul li { display: inline-block;  }	
    .filters .filters__aside ul li + li { margin-left: 5px; margin-top: 10px; }	
    .filters .filters__aside ul li a span {font-size: 14px;}
    .filters .filters__aside .btn { display: inline-block; }	
  }

@media (max-width: 474px) {
  .filters { padding-left: 0; width: 100%; }
  .filters { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }	
  .filters .filters__aside { width: 100%; margin-top: 10px; }	
  .filters .filters__aside ul { text-align: center; }
  .filters .filters__aside ul .sort-title { display: block !important; }	
  .filters .filters__aside ul li { display: inline-block;  }	
  .filters .filters__aside ul li + li { margin-left: 3px; margin-top: 10px; }	
  .filters .filters__aside ul li a span {font-size: 12px;}
  .filters .filters__aside .btn--sort {
    padding: 5px !important;
  }
  .filters .filters__aside .btn {
    font-size: 12px;
    padding: 5px 5px;
}
  .filters .filters__aside .btn { display: inline-block; }	
}
@media (max-width: 321px) {
  .filters .filters__aside .btn--sort {
    padding: 5px 40px !important;
  }
}
  /* ------------------------------------------------------------ *\
    Form Elements
\* ------------------------------------------------------------ */
  input::-webkit-input-placeholder { color: inherit; opacity: 1; }
  input:-ms-input-placeholder { color: inherit; opacity: 1; }
  input::-ms-input-placeholder { color: inherit; opacity: 1; }
  input::placeholder { color: inherit; opacity: 1; }
  textarea::-webkit-input-placeholder { color: inherit; opacity: 1; }
  textarea:-ms-input-placeholder { color: inherit; opacity: 1; }
  textarea::-ms-input-placeholder { color: inherit; opacity: 1; }
  textarea::placeholder { color: inherit; opacity: 1; }
  input:-webkit-autofill { -webkit-text-fill-color: inherit; -webkit-box-shadow: 0 0 0 1000px #fff inset;
    box-shadow: 0 0 0 1000px #fff inset;
  }
  /* Field */
  /* .field { font-size: 15px; padding: 14px 21px 11px; border: none; width: 100%; min-height: 48px; border-radius: 0; } */
  /* Mobile */
  @media (max-width: 767px) {
    /* .field { font-size: 14px; padding: 5px 10px; min-height: 40px; } */
}
  /* Checkbox */
  .checkbox { background-color: rgba(0, 0, 0, 0.7); padding: 9px 17px; display: block; position: relative; min-height: auto; border-radius: 5px}
  .checkbox label { color: #fff; font-size: 14px; line-height: 1; padding-left: 26px; position: relative; display: inline-block; vertical-align: middle; }
  .checkbox label:after { content: ''; position: absolute; top: 0; bottom: 0; left: 0; margin: auto; width: 16px; height: 16px; background-color: #fff; }
  .checkbox label:before { content: ''; position: absolute; left: 3px; top: 0; bottom: 0; margin: auto; background-image: url(../images/ico-check@2x.png); background-size: 100% 100%; width: 9px; height: 7px; z-index: 5; opacity: 0; visibility: hidden; -webkit-transition: opacity .3s, visibility .3s; -o-transition: opacity .3s, visibility .3s; transition: opacity .3s, visibility .3s; }
  .checkbox input { position: absolute; top: 0; left: 0; opacity: 0; visibility: unset; width: 1px; height: 1px; line-height: 1; }
  .checkbox input:checked ~ label:before { opacity: 1; visibility: visible; }
  .checkbox input:checked ~ label:after { background-color: #1576D1; }
  /* Checkbox Alt */
  .checkbox--alt { background-color: transparent; color: #333; padding: 0 34px 0 0; min-height: auto; }
  .checkbox--alt label { color: #333; padding-left: 36px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; font-size: 15px; }
  .checkbox--alt label small { font-size: 15px; color: #000000; min-width: 100%; width: 100%; display: inline-block; vertical-align:  text-top; }
  .checkbox--alt label span { min-width: 100%; width: 100%; display: inline-block; vertical-align: text-top;}
  .checkbox--alt label:after { border: 1px solid #bdbfc0; top: 0; bottom: auto; }
  .checkbox--alt label:before { top: 5px; bottom: auto; }
  .checkbox--alt input:checked ~ label:after { border-color: #1576D1; }
  .checkbox--alt input:checked ~ label small,
.checkbox--alt input:checked ~ label { color: #1576D1; }
  /* Tablet Portrait */
  @media (max-width: 1023px) {
    .checkbox--alt label span,
    .checkbox--alt label small { width: auto; min-width: auto; }
}
  /* Mobile */
  @media (max-width: 767px) {
    .checkbox { padding: 10px; min-height: auto; }
    .checkbox label { font-size: 12px; }
    
    .checkbox--alt { padding: 0; }
}
  /* Select */
  .select .select2-container { width: 100% !important; }
  .select .select2-container--default .select2-selection--single { border: none; width: 100%; border-radius: 0; height: 48px; }
  .select .select2-container .select2-selection--single .select2-selection__rendered { font-size: 15px; line-height: 48px; padding-left: 24px; padding-right: 45px; }
  .select .select2-container--default .select2-selection--single .select2-selection__arrow { border: 1px solid #e5e5e5; top: 0; bottom: 0; right: 0; height: 100%; width: 45px; }
  .select .select2-container--default .select2-selection--single .select2-selection__arrow b { border: none; background-image: url(../images/temp/ico-arrow-select.png); width: 14px; height: 9px; background-size: 100% 100%; margin-left: -6px; }
  /* Mobile */
  @media (max-width: 767px) {
    
    .select .select2-container .select2-selection--single { height: 40px; }
    .select .select2-container .select2-selection--single .select2-selection__rendered { font-size: 14px; padding-left: 10px; line-height: 40px; }
}
  /* Select Post */
  .select-post .select2-container { width: 205px !important; }
  .select-post .select2-container--default .select2-selection--single { border-left: 1px solid #dddedf; border-right: 1px solid #dddedf; border-top: none; border-bottom: none; border-radius: 0; height: 50px; width: 100%; }
  .select-post .select2-container .select2-selection--single .select2-selection__rendered { line-height: 50px; font-size: 16px; color: #1576D1; padding-left: 25px; padding-right: 39px; }
  .select-post .select2-container--default .select2-selection--single .select2-selection__arrow { top: 0; bottom: 0; height: 100%; width: 45px; }
  .select-post .select2-container--default .select2-selection--single .select2-selection__arrow b { border: none; background-image: url(../images/temp/ico-arrow-select.png); width: 14px; height: 9px; background-size: 100% 100%; margin-left: -13px; margin-top: -5px; }
  /* Mobile */
  @media (max-width: 767px) {
    .select-post .select2-container { width: 175px !important; }
    .select-post .select2-container .select2-selection--single .select2-selection__rendered { font-size: 14px; padding-left: 10px; }
    
}
  /* ------------------------------------------------------------ *\
    Form
\* ------------------------------------------------------------ */
  .form .form__label { background: rgba(0, 0, 0, 0.7); color: #fff; padding: 9px 21px 7px; font-weight: 500; font-size: 14px; }
  .form .form__cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }
  .form .form__col--1of12 { width: 100%; }
  .form .form__col { width: 100%; }
  .form .form__col--1of9 { width: 74%; }
  .form .form__col--1of6 { width: 49%; }
  .form .form__col--1of3 { width: 23%; }
  .form .form__expand { margin-top: 19px; display: none; }
  .form .form__date { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; position: relative; }
  .form .form__date .field { width: 49%; }
  .form .form__actions { text-align: right; margin-top: 40px; }
  .form .form__actions a + a { margin-left: 25px; }
  .form .form__row + .form__row { margin-top: 19px; }
  /* Mobile */
  @media (max-width: 767px) {
    .form .form__cols { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .form .form__col { width: 100%; }
    
    .form .form__label { padding: 5px; display: inline-block; font-size: 12px; }
    
    .form .form__actions { margin-top: 20px; text-align: center; }
    .form .form__actions a { display: block; }
    .form .form__actions a + a { margin-left: 0; margin-top: 5px; }

    .form .form__date { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .form .form__date .field { width: 100%; }
    .form .form__date .field:nth-child(4) { margin-top: 10px; }
    
    .form .form__expand,
    .form .form__row + .form__row,
    .form .form__col + .form__col { margin-top: 10px; }
    
}
  /* ------------------------------------------------------------ *\
    icons
\* ------------------------------------------------------------ */
  .ico-hammer { width: 54px; height: 54px; display: block; }
  .ico-siren { width: 54px; height: 54px; display: block;  }
  .ico-inkpot { width: 54px; height: 54px; display: block; }
  .ico-guide { width: 54px; height: 54px; display: block; }
  .ico-checkbox { width: 54px; height: 54px; display: block; }
  .ico-facebook { width: 10px; height: 19px; display: block; }
  .ico-twitter { width: 18px; height: 18px; display: block; }
  .ico-youtube { width: 20px; height: 15px; display: block; }
  .ico-youtube svg,
.ico-twitter svg,
.ico-facebook svg,
.ico-checkbox svg,
.ico-guide svg,
.ico-inkpot svg,
.ico-siren svg,
.ico-hammer svg { width: 100%; height: 100%; display: block; }
  /* ------------------------------------------------------------ *\
    Intro
\* ------------------------------------------------------------ */
  .intro {  
    background-size: cover; background-position: center center; background-repeat: no-repeat;
    padding: 86px 0 5px;
    -moz-transition: height 1s ease;
    -webkit-transition: height 1s ease;
    -o-transition: height 1s ease;
    transition: height 1s ease;
    overflow: hidden;
  }

  .intro.home-header {
    background-image: url(../images/temp/intro-bg-3.jpg);background-size: cover; background-position: center; background-repeat: no-repeat;
  }
  /* .intro.intro--post {
    background-image: url(../images/temp/intro-bg-2.png);background-size: cover; background-position: center top; background-repeat: no-repeat;
  } */

  .intro.expended {
    height: 782px; 
  }
  .intro.mini {
    height: 800px;
    }
    
  .intro .intro__head { margin-left: 11px; margin-bottom: 40px; display: flex; justify-content: left;}
  .intro .intro__subhead { margin-bottom: 10px; }
  .intro .intro__head h1 { color: #fff; font-weight: 400; }
  .intro__head__status {position: relative;margin-left: 0px;}
    .intro__head__status__wraper {
      margin: 0;
    }
  .intro .intro__tag { display: inline-block; vertical-align: middle; margin-left: 10px;font-size: 14px; padding: 5px 11px;border-radius: 5px; border:1px solid white; }
  
  .intro .intro__tag.green { background-color: #56a840; color: #fff; }
  .intro .intro__tag.blue { background-color: #1576D1; color: #fff; }
  .intro .intro__tag.orange { background-color: #e59136; color: #fff; }
  .intro .intro__tag.purple { background-color: #c67ae6; color: #fff; }

  .intro .intro__foot { margin-top: 40px; }
  
  /* Mobile */
  @media (max-width: 767px) {
  .intro { padding: 46px 0; }
  .intro.expended {
    height: 1150px; 
  }
  .intro.mini {
    height: 550px;
    }	
    .intro .intro__tag { margin-left: 0; margin-top: 2px; white-space: nowrap; padding:2px 7px; font-size:12px;}
  .intro .intro__foot { margin-top: 10px; }
}
  /* ------------------------------------------------------------ *\
    Intro Post
\* ------------------------------------------------------------ */
  .intro--post { padding: 56px 0; }
  .intro--post .intro__head { margin-bottom: 0px; margin-left: 0; }
  .intro--post .intro__head .h1 { line-height: 1.39; display: inline; vertical-align: middle; }
  .intro--post .intro__entry { color: #fff; margin-top: 33px;}
  .intro--post .intro__entry p { font-size: 14px; opacity: .8; }
  /* Mobile */
  @media (max-width: 767px) {
    .intro--post { padding: 46px 0; }	
    .intro--post .intro__head { margin-bottom: 10px; }
    .intro--post .intro__head .h1 { display: block; }
    .intro--post .intro__entry { margin-top: 10px; }
    .intro--post .intro__entry p { font-size: 12px;}
}
  /* ------------------------------------------------------------ *\
    Link
\* ------------------------------------------------------------ */
  .link { color: #fff; text-decoration: none; -webkit-transition: opacity .3s; -o-transition: opacity .3s; transition: opacity .3s; font-size: 14px; }
  .link span { padding-left: 5px; }
  .link:hover { opacity: .8; }
  /* ------------------------------------------------------------ *\
    List
\* ------------------------------------------------------------ */
  [class^="list-"] { list-style: none outside none; }
  /* List Checkboxes */
  .list-checkboxes li + li { margin-top: 10px; }
  .list-checkboxes li:nth-child(n+6) { opacity: 0; visibility: hidden; height: 0; margin-top: 0; -webkit-transition: opacity .3s, visibility .3s, height .3s; -o-transition: opacity .3s, visibility .3s, height .3s; transition: opacity .3s, visibility .3s, height .3s;  }
  .list-checkboxes.active li:nth-child(n+6) { opacity: 1; visibility: visible; height: auto; margin-top: 21px; }
  /* Tablet Portrait */
  @media (max-width: 1023px) {
    .list-checkboxes li + li { margin-top: 10px; }
    .list-checkboxes.active li:nth-child(n+6) { margin-top: 10px; }
    
}
  /* List Checks */
  .list-checks {background-color: white;}
  .list-checks li { display: inline-block; vertical-align: top; text-align: center; position: relative; width: 160px; }
  .list-checks p { color: black; }
  .list-checks li + li { margin-left: 60px; }
  .list-checks .check { background-color: #1576D1; width: 46px; height: 46px; border-radius: 50%; position: relative; display: block; margin: 0 auto 24px; }
  .list-checks .check svg { position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto; }
  .list-checks .check--empty { background-color: transparent; border: 2px solid grey; }
  .list-checks .check--empty svg { display: none; }
  /* .list-checks .check:after { content: ''; position: absolute; top: 0; bottom: 0; left: 100%; margin: auto; width: 176px; height: 2px; background-color: rgba(255, 255, 255, 0.3); } */
  .list-checks li:last-child .check:after { display: none; }
  /* Small Desktop */
  @media (max-width: 1200px) {
    .list-checks li + li { margin-left: 20px; }
    .list-checks .check:after { width: 141px; }
}
  /* Tablet Portrait */
  @media (max-width: 1023px) {
    .list-checks li { width: 100px; }	
    .list-checks p { font-size: 14px; }
    .list-checks .check:after { width: 76px; }
    .list-checks .check { margin-bottom: 10px; }
}
  /* Mobile */
  @media (max-width: 767px) {
    .list-checks li { width: 100%; display: block; }	
    .list-checks p { font-size: 12px; }	
    .list-checks li + li { margin-left: 0; margin-top: 30px; }
    .list-checks li + li:before { content: ''; position: absolute; bottom: 100%; left: 0; right: 0; width: 2px; height: 30px; margin: auto; background-color: rgba(255, 255, 255, 0.3); }
    .list-checks .check:after { display: none; }
}
  /* List links */
  .list-links li + li { margin-top: 25px; padding-top: 25px; border-top: 1px solid #e5e5e5; }
  .list-links a { text-decoration: none; -webkit-transition: opacity .3s; -o-transition: opacity .3s; transition: opacity .3s; }
  .list-links a:hover { opacity: .7; }
  .list-links span { display: inline-block; vertical-align: middle; color: #1576D1; }
  .list-links span svg { display: block; }
  .list-links span + span { padding-left: 10px; }
  /* Mobile */
  @media (max-width: 767px) {
    .list-links li + li { margin-top: 10px; padding-top: 10px; }	
}
  /* ------------------------------------------------------------ *\
    Logo
\* ------------------------------------------------------------ */
  .logo { text-decoration: none; display: block; margin-bottom: -48px; font-size: 0; }
  .logo .logo-text { margin-left: 22px; margin-top: 36px; vertical-align: top; }
  .logo-footer { font-size: 0; }
   .header__content {
    max-width: 9999px;
  }
  @media (min-width: 768px) and ( max-width:1078px) {
    .logo .logo-text { 
      position: absolute;
      left: 132px; 
      top: 0px;
    }
    .header__content { 
      max-width: 319px;
    }
    .header__content li {
      margin-top: 11px;
    }
    .header__link_item {
      font-size: 14px !important;
    }
  }
 
  /* Mobile */
  @media (max-width: 767px) {
    .logo { margin-bottom: -38px; }
    .logo .logo-text { display: none; }
    
    .logo-footer .logo-image,
    .logo .logo-image { width: 75px; height: 75px; }
    
}
  /* ------------------------------------------------------------ *\
    Nav
\* ------------------------------------------------------------ */
  .nav li { display: inline-block; vertical-align: middle; }
  .nav li + li { margin-left: 40px; }
  .nav a { text-decoration: none; 
    /* font-size: 16px;  */
  }
  /* Tablet Portrait */
  @media (max-width: 1023px) {
    .nav li + li { margin-left: 20px; }
    
}
  /* Mobile */
  @media (max-width: 767px) {
    .nav a { font-size: 14px; }
    .nav li + li { margin-left: 10px; }
}
  /* ------------------------------------------------------------ *\
    Pdf
\* ------------------------------------------------------------ */
  .pdf-canvas { width: 100%; height: 483px; overflow: auto; border: 1px solid #e3e3e3; }
  .pdf-canvas .pdf-canvas__inner { width: 100%; }
  .pdf-view { text-align: right; }
  .pdf-view .pdf__button { color: #fff; background-color: #1576D1; border: none; margin-right:15px;}
  .pdf-view .pdf__actions { margin-bottom: 10px; }
  .pdf-view .pdf__actions span { font-size: 15px; }
  /* Mobile */
  @media (max-width: 767px) {
    .pdf-canvas { height: 200px; }
    .pdf-view { text-align: center; }
    .pdf-view .pdf__actions { margin-bottom: 5px; }
    .pdf-view .pdf__actions span { font-size: 12px; }
    .pdf-view .pdf__button { font-size: 12px; padding: 5px; }
    
}
  /* ------------------------------------------------------------ *\
    Post
\* ------------------------------------------------------------ */
  
  .posts .posts__actions { margin-top: 11px; }
  .post__content.summary { font-size:16px; line-height: 1.7; }
  .posts .posts__form { background-color: #fff; border-bottom: 2px solid #1576D1; min-height: 50px; margin-bottom: 12px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  .posts .posts__form label { font-size: 14px; color: #666; padding: 13px 26px; }
  .posts .post { background-color: #fff; -webkit-box-shadow: 0px 2px 0px 0px rgba(53, 142, 224, 0.004); box-shadow: 0px 2px 0px 0px rgba(53, 142, 224, 0.004); margin-bottom: 10px; padding: 10px 29px; }
  .posts .post.summary {background-color:#fafafa}
  .post a { text-decoration: none; display: inline; }
  .post:hover .post__title { text-decoration: underline; }
  .post .post__meta { margin-bottom: 5px;min-width: 9em; font-weight: bolder;padding-top: 0.27rem;}
  .post .post__title { font-weight: 400; color: #1576D1; }
  .post__date {
      padding-top: 0.42rem;
    }
  /* Mobile */
  @media (max-width: 767px) {
    .posts .post { padding: 10px; }
    .post__content.summary { font-size:14px; line-height: 1.5;margin-top: 0.8rem;}
    .post .post__meta { font-size: 14px; margin-bottom: 0; min-width: 6em;}
    .post__date {
      display: inline-block;
      margin-right: 20px;
      float:  right;
    }
    .post__date.additional_info_label {
      display: inline-block;
      margin-right: 0px;
      float: none;
    }
    .posts .posts__form { min-height: auto; }
    .posts .posts__form label { padding: 13px 10px; }
}
  /* ------------------------------------------------------------ *\
    Post Large
\* ------------------------------------------------------------ */
  .posts .post--large { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: normal; padding: 0 0 0 39px; }
  .posts .post--large + .post--large { margin-top: 0px; }
  .post--large .post__date { line-height: 1.0; padding-left: 0; margin-top: 0; }
  .post--large .post__date p { font-size: 14px; color: #000000; display: inline-block; vertical-align: middle; margin-bottom: 0; font-size:0.9em !important; }
  .post--large .post__tag { padding: 4px 6px; 
    border-radius: 4px;
    color: #fff; font-size: 12px; display: inline-block; vertical-align:middle; line-height: 1; margin-left: 3px; margin-bottom: 5px; }
    .post--large .post__tag.custom_tag {
      color: black;
      margin-left: 0.5rem;
    }
  .post--large .post__tag.orange { background-color: #e59136; }
  .post--large .post__tag.blue { background-color: #1576D1; }
  .post--large .post__tag.green { background-color: #56a840; }
  .post--large .post__tag.purple { background-color: #c67ae6; }
  .post--large .post__foot { color: #1576D1; margin-top: 8px; }
  .post--large .post__foot span { display: inline-block; vertical-align: middle; font-size: 14px; }
  .post--large .post__foot span svg { display: block; }
  .post--large .post__foot span + span { margin-left: 6px; }
  /* Tablet Portrait */
  @media (max-width: 1023px) {
    .posts .post--large { padding: 0; }
    .posts .post--large + .post--large { margin-top: 0px; }
    .post--large .post__meta { width: 15%; }
    .post--large .post__content { width: 85%; padding-left:3.0em;}
}
  /* Mobile */
  @media (max-width: 767px) {
    .posts .post--large { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .post--large .post__meta,
    .post--large .post__content { width: 100%;  padding-left:0;}
    .post--large .post__tag { margin-left: 5px; }
    .post--large .post__date { padding-left: 0; margin-top: 0; }
    .post--large .post__foot { margin-top: 0; }
}
  /* ------------------------------------------------------------ *\
    Post Alt
\* ------------------------------------------------------------ */
  .posts .post--alt { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: normal; padding: 0; margin-bottom: 0; }
  .posts .post--alt + .post--alt { margin-top: 25px; padding-top: 25px; border-top: 1px solid #e5e5e5; }
  .posts .post--alt + .post--alt.summary {margin-top: 10px; padding-top: 10px;border-top: none;}
  .post--alt .post__date { width: 14%; }
  .post--alt .post__content { width: 86%; padding-left:3.0em;}
  .post--alt .post__content .h5 { font-weight: 400; }
  .post--alt .post__content a { color: #1473CC; margin-top: 10px; }
  /* Mobile */
  @media (max-width: 767px) {
    .posts .post--alt { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .posts .post--alt + .post--alt { margin-top: 10px; padding-top: 10px; }
    .post--alt .post__content a { margin-top: 0; }
    .post--alt .post__date,
    .post--alt .post__content { width: 100%;  padding-left:1.0em; margin-top: 0.5rem;}
}
  /* ------------------------------------------------------------ *\
    Section
\* ------------------------------------------------------------ */
  .section { padding: 70px 0 95px; }
  .section .section__head { margin-bottom: 23px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  .section .section__title { font-weight: 400; }
  
  .section .section__title span { font-weight: 400; }
  .section .section__cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;
    transition: width 0.5s ease; 
  }
  .section .section__col--1of2 { width: 49%; }
  /* Mobile */
  @media (max-width: 767px) {
    .section { padding: 10px 0; }

    .section .section__head { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; margin-bottom: 25px; }

    .section .section__cols { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .section .section__col { width: 100%; }
    .section .section__col + .section__col { margin-top: 20px; }
}
  /* ------------------------------------------------------------ *\
    Section inkblue
\* ------------------------------------------------------------ */
  .section--inkblue { background-color: transparent; }
  .section--inkblue .section__title { color: #fff; }
  /* ------------------------------------------------------------ *\
    Section Gray
\* ------------------------------------------------------------ */
  .section--gray { background-color: #e0e1e2; }
  /* ------------------------------------------------------------ *\
    Section Results
\* ------------------------------------------------------------ */
  .section-results { position: relative; min-height: 400px; line-height: 2.0;background-color: #ffffff;}
  .section-results:after { content: ''; position: absolute; top: 66px; left: 0; right: 0; width: 100%; height: 2px; background-color: #e0e1e2; }
  .section-results .section__bar { border-bottom: 1px solid #e0e1e2; }
  .section-results .section__head { padding: 15px 0; min-height: 66px; }
  .section-results .section__head .h6 { font-weight: 400; }
  .section-results .section__body { margin-top: 33px; padding-bottom: 130px; }
  .section__body.summary { border: 1px solid lightgray; padding: 20px 20px; background-color: #e0e0e026; }
  .section-results .section__cols { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }
  .section-results .section__col--size1 { width: 20%; opacity: 1; transition: opacity 0.5s; padding-left:20px}
  .section-results .section__col--size1.hidden {opacity: 0;}
  .section-results .section__col--size2 { width: 79%; padding-right: 20px;}
  .section-results .section__col + .section__col { border-left: 1px solid #e0e1e2; }
  .section-results .section__paging { padding: 10px 40px; padding-top:0px}
  .section-results .section__paging--border-bottom { padding:10px 30px; }
  /* .section-results .section__paging--border-top { border-top: 0px solid #e0e1e2; } */
  .section-results .section__paging--border-top { border-top:none; }
  /* Tablet Portrait */
  @media (max-width: 1500px) {
    .section-results .section__col--size1 { width: 20%; opacity: 1; transition: opacity 0.5s; padding-left:40px}
  }

  @media (max-width: 1300px) {
    .section-results .section__col--size1 { width: 25%; opacity: 1; transition: opacity 0.5s; padding-left:40px}
  }

  @media (max-width: 1140px) {
    .section-results .section__col--size1 { width: 30%; opacity: 1; transition: opacity 0.5s; padding-left:40px}
  }
  @media (max-width: 1024px) {
    .section-results:after { display: none; }
    .section__body.summary { border: 1px solid lightgray; padding: 10px 10px; background-color: #e0e0e026; }
    .section-results .section__head { min-height: auto; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; border-bottom: 2px solid #e0e1e2; padding: 10px 0; }
    .section-results .section__body { margin-top: 10px; padding-bottom: 20px; }
    .section-results .section__cols { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .section-results .section__col--size1,
    .section-results .section__col--size2 { width: 100%; padding:0 20px; }
    .section-results .section__col--size2 { padding-bottom: 60px; }
    .section-results .section__col + .section__col { border-left: 0; }
}
  /* ------------------------------------------------------------ *\
    Secttion Details
\* ------------------------------------------------------------ */
  /* .section-details { border-top: 10px solid #e0e1e2; border-bottom: 1px solid #dddedf; } */
  .section-details .section__rows { -webkit-box-shadow: 0px 1px 0px 0px rgba(221, 222, 223, 0.004); box-shadow: 0px 1px 0px 0px rgba(221, 222, 223, 0.004); }
  .section-details .section__row + .section__row { border-top: 2px solid #e0e1e2; }
  .section-details .section__row--gray { background-color: #e0e1e2; }
  /* .section-details .section__row span { color: #1576D1; } */
  .section-details .section__row span { color: black; }
  .section-details .section__cols { display: -webkit-box; display: -ms-flexbox; display: flex; }
  .section-details .section__col--1of2 { min-width: 150px; padding: 11px 0; }
  .section-details .section__col--2of2 { padding: 11px 0; }
  
  .section-details .section__col + .section__col { border-left: 2px solid #e0e1e2; padding-left: 29px; }
  .section-details .section__head { margin-bottom: 40px; }
  .section-details .section__head .h2 { font-weight: 400; }
  .section-details--alt { padding: 32px 0; }
  .section-details--no-border { border: none; }
  /* Mobile */
  @media (max-width: 767px) {
    .section-details .section__col + .section__col { padding-left: 15px; }	
    .section-details .section__col p { font-size: 13px; }	
    .section-details .section__head { margin-bottom: 15px; }	
    
    .section-details--alt { padding: 20px 0; }	
}
  /* ------------------------------------------------------------ *\
    Services
\* ------------------------------------------------------------ */

  .services { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; margin: 0px;
    perspective: 500px; transform-style: preserve-3d;
  }
  .service:hover {cursor: pointer;}
  .services .service {  
    width: 19.9%; background-color: rgba(255, 255, 255, 0.999); transform: rotateY(0deg);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    box-shadow: none;
    height: 272px; display:
    -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;
    -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;
    -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding: 20px; 
  }
  .services .service:hover {
    box-shadow: 0 0 5px 1px whitesmoke;
    transform: rotateY(0deg) translate3d(0, 0, 5px);
  }
  .services .service.left1 {
    margin-left: 0;
  }
  .services .service.left1:hover {
    box-shadow: 0 0 5px 1px whitesmoke;
    transform: rotateY(0deg) translate3d(0, 0, 5px);
  }
  .services .service.left2:hover {
    box-shadow: 0 0 5px 1px whitesmoke;
    transform: rotateY(0deg) translate3d(0, 0, 5px);
  }
  .services .service.right1:hover {
    box-shadow: 0 0 5px 1px whitesmoke;
    transform: rotateY(0deg) translate3d(0, 0, 5px);
  }
  .services .service.right2 {
    margin-right: 0;
  }
  .services .service.right2:hover {
    box-shadow: 0 0 5px 1px whitesmoke;
    transform: rotateY(0deg) translate3d(0, 0, 5px);
  }
  
  @media (max-width: 1024px){
    .services { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;
      perspective: 0px; transform-style: preserve-3d;
    }
    .service:hover {cursor: pointer;}
    .services .service {  
      margin:10px;
      width: 19.9%; background-color: rgba(255, 255, 255, 0.999); transform: rotateY(0deg);
      transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
      box-shadow: none;
      height: 272px; 
      display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center;
      justify-content: center; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; 
      flex-direction: column; -webkit-box-align: center; -ms-flex-align: center; align-items: center; 
      padding: 20px;
    }
    .services .service:hover {
      box-shadow: 0 0 5px 1px whitesmoke;
      transform: rotateY(0deg) translate3d(0, 0, 3px);
    }

    .services .service.left1 {
      margin-left: 10px;
    }
    .services .service.right2 {
      margin-right: 10px;
    }
    .services .service.left1:hover {
      box-shadow: 0 0 5px 1px whitesmoke;
      transform: rotateY(0deg) translate3d(0, 0, 3px);
    }
    .services .service.left2:hover {
      box-shadow: 0 0 5px 1px whitesmoke;
      transform: rotateY(0deg) translate3d(0, 0, 3px);
    }
    .services .service.right1:hover {
      box-shadow: 0 0 5px 1px whitesmoke;
      transform: rotateY(0deg) translate3d(0, 0, 3px);
    }
    .services .service.right2:hover {
      box-shadow: 0 0 5px 1px whitesmoke;
      transform: rotateY(0deg) translate3d(0, 0, 3px);
    }
  }

  .service .service__image { margin-bottom: 37px; }
  .service .service__content { text-align: center; width: 100%; }
  .service .service__content span { font-weight: 600; font-size: 36px; line-height: 1; display: block; margin-bottom: 15px; }
  .service .service__content .h6 { font-weight: 400; }
  .service .section__title { font-weight: 500; }
  /* Tablet Portrait */
  @media (max-width: 1023px) {	
    .services { -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
    .services .service { width: 33.3%; }
}
  /* Mobile */
  @media (max-width: 767px) {
    .services { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;}
    .services .service { width: 85%; margin:auto; margin-bottom: 5px; height: 180px; }
    .services .service.left1 {
      margin: auto; margin-bottom: 5px;
    }
    .services .service.right2 {
      margin: auto;  margin-bottom: 5px;
    }
    .service .service__image { margin-bottom: 10px; }
    .service .service__content { min-height: auto;  }
    .service .service__content span { margin-bottom: 5px; }
}
  /* ------------------------------------------------------------ *\
    Slider
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Socials
\* ------------------------------------------------------------ */
  .socials { text-align: center; }
  .socials ul { list-style-type: none; }
  .socials li { display: inline-block; }
  .socials a { display: block; width: 44px; height: 44px; background-color: #000000; border: 1px solid #fff; border-radius: 50%; position: relative; -webkit-transition: background .3s; -o-transition: background .3s; transition: background .3s; }
  .socials a span { position: absolute; top: 0; left: 0; bottom: 0; right: 0; margin: auto; }
  .socials a small { font-size: 0; line-height: 0; }
  .socials a:hover { background-color: rgba(255,255,255,0.5); }
  .socials li + li { margin-left: 15px; }
  /* ------------------------------------------------------------ *\
    Tabs
\* ------------------------------------------------------------ */
  .tab { display: none; -webkit-transition: opacity .5s, -webkit-transform .5s; transition: opacity .5s, -webkit-transform .5s; -o-transition: transform .5s, opacity .5s; transition: transform .5s, opacity .5s; transition: transform .5s, opacity .5s, -webkit-transform .5s; -webkit-transform: scale(.9); -ms-transform: scale(.9); transform: scale(.9); opacity: 0; }
  .tab.current { display: block; }
  .tab.translating-in { -webkit-transform: scale(1); -ms-transform: scale(1); transform: scale(1); opacity: 1; }
  .tab.translating { -webkit-transform: scale(.9); -ms-transform: scale(.9); transform: scale(.9); opacity: 0; }
  .tabs .tabs__head { margin-bottom: 12px; min-height: 51px; height: 100%; }
  .tabs .tabs__nav { border-bottom: 2px solid #1576D1; background-color: #fff; height: 100%; }
  .tabs .tabs__nav li { display: inline-block; }
  .tabs .tabs__nav a { display: block; font-size: 16px; padding: 11px 29px; text-decoration: none; -webkit-transition: color .3s, background-color .3s; -o-transition: color .3s, background-color .3s; transition: color .3s, background-color .3s; height: 100%; }
  .tabs .tabs__nav a:hover,
.tabs .tabs__nav .current a { background-color: #1576D1; color: #fff; }
  /* Mobile */
  @media (max-width: 767px) {
    .tabs .tabs__head { min-height: auto; }	
    .tabs .tabs__nav a { font-size: 14px; padding: 10px; }	
}
  /* ------------------------------------------------------------ *\
    Table
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
    Widget
\* ------------------------------------------------------------ */
  .widgets { list-style: none outside none; }
  .widget + .widget { margin-top: 20px; }
  .widget .widget__head { margin-bottom: 10px; }
  .widget .widget__head .h5 { font-weight: 600; }
  .widget .widget__foot { padding-left: 34px; margin-top: 15px; }
  .widget .widget__foot .link { text-decoration: none; color: #1576D1; }
  .widget .widget__foot .hide { display: none; }
  .widget .widget__foot .link.active .hide { display: block; }
  .widget .widget__foot .link.active .show { display: none; }
  /* Tablet Portrait */
  @media (max-width: 1023px) {
    .widget .widget__head { margin-bottom: 10px; }	
    .widget + .widget { margin-top: 20px; }
}
  /* ------------------------------------------------------------ *\
    Themes
\* ------------------------------------------------------------ */

.ui.input > input {
  border-radius:5px;
}

.form__label {
  border-radius:5px;
}

.logo {
  position: relative;
  z-index:10;
}
.ui.fluid.selection.dropdown {
  border-radius:5px;
}
.ui.icon.input>i.link.icon {
  color:black;
}

p {
  line-height: 1.75;
}


.list-checks-line {
  content: ''; position: absolute; top: 0; bottom: 0; left: 100%; margin: auto; width: 176px; height: 2px; background-color: grey;

}

.dropdown.icon {
  color:#1576D1;
}

.posts.pagination {
  display: flex;
  
}
.ui.secondary.pointing.menu {
  border:none;
}
.ui.segment.condition-list-segment {
  margin-left:10px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container.condition-list-container {
  justify-content: left;
}


.condition-list-unit {
  font-size:84%;
  border: solid 1px #1576D1;
  background-color:   #1576D1;
  border-radius: 5px;
  margin: 2px;
  margin-left: 5px;
  margin-bottom: 6px;
  padding : 1px 5px;
  flex-wrap: wrap;
  color: white;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}
.condition-list-unit:hover {
  background-color:   #1576D180;
  cursor: pointer;
}

@media (max-width: 767px){
  body {
    padding-top: 0px;
  }
}

.service {
  border-radius: 5px;
  margin-left:10px;
  margin-right:10px;
}
@media (max-width: 1023px) {
  .service {
    margin-top:10px;
    margin-left:10px;
    margin-right:10px;
  }
}
@media (max-width: 767px) {
    .service {
    width: 80%;
    margin-top:10px;
    margin:auto;
  }	
}
.condition-list-unit.condition-list-unit-final {
  justify-content: flex-end;
}

.mobil-search-container {
  width:100%;
  position: relative;
}

.intro--blue { background-color: #155d9f; }

#root > div > div > div > div > div:nth-child(3) > div > div.intro__body > div > form > div > div > div > div.form__col.form__col--1of9 > div > div > div > div > div.visible.menu.transition {
  max-height: 200px;
}
.visiable.menu.transition {
  max-height: 200px; 
}

.intro__body {
  -webkit-transition: min-height .5s; -o-transition: min-height .5s; transition: min-height .5s;
}

.right-floated-accordion-icon {
  float:right;
  margin-right: 13px;
  width:"21px";
  height:"21px";
}
.right-floated-accordion-icon:hover {
  cursor: pointer;
}
@media (max-width: 1023px) {
  .right-floated-accordion-icon {
    margin-right: 25px;
  }
}
@media (max-width: 767px) {
  .right-floated-accordion-icon {
    margin-right: -2px;
  }
}
.expend-icon {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  transition-property: transform;
  transform: rotate(0deg);
  transform-origin: 50% 100%;
}
.icon-90 {
  -webkit-transform:rotate(90deg);
  -moz-transform:rotate(90deg); 
  -o-transform:rotate(90deg);
  transform: rotate(90deg);
  transform-origin: 50% 100%;
}



.circular.ui.icon.button.black:hover {
  opacity: 0.8;
  border: 1px solid grey;
  transition: 0.5s ease;
}
.display-inline {
  display: inline-block;
}
.circular.ui.icon.button.black {
  opacity: 1.0;
  background-color: #1576D1;
  border: 1px solid transparent;
  transition: 0.5s ease;
}

.ui.button:hover {
  background-color:#f1f5fb;
}

/* ------------------------------------------------------------ *\
	Paging
\* ------------------------------------------------------------ */
.paging { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.paging ul { list-style-type: none; margin-bottom: 0; }
.paging li { display: inline-block; vertical-align: middle; }
.paging li + li { margin-left: 5px; }
.paging li a { display: block; border: 1px solid #e5e5e5; font-size: 14px; text-decoration: none; width: 40px; height: 40px; line-height: 40px; text-align: center; -webkit-transition: border .3s, color .3s; -o-transition: border .3s, color .3s; transition: border .3s, color .3s; }
.paging li a:hover,
.paging .active a { border-color: #0776dc; color: #0776dc; }
.paging .paging__spacer { margin: 0 15px 0 30px; }
.paging .paging__next,
.paging .paging__prev { font-size: 14px; display: block; border: 1px solid #e5e5e5; padding: 9px 20px 11px; text-decoration: none; -webkit-transition: border .3s, background .3s, color .3s; -o-transition: border .3s, background .3s, color .3s; transition: border .3s, background .3s, color .3s; }
.paging .paging__next:hover,
.paging .paging__prev:hover { border-color: #0776dc; }
.paging .paging__next span + span,
.paging .paging__prev span + span { margin-left: 20px; }
.paging .paging__prev span { display: inline-block; vertical-align: middle; }
.paging .paging__prev { margin-right: 5px; }
.paging .paging__next { margin-left: 5px; }
.paging .paging__prev .ico-arrow-right { -webkit-transform: rotate(-180deg); -ms-transform: rotate(-180deg); transform: rotate(-180deg); }
.paging .paging__prev .ico-arrow-right {
  background-image: url(../images/sprite.png);
    background-position: 57.88043478260869% 28.26086956521739%;
    background-size: 2400% 1250%;
    width: 16px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0px;
}
.paging .paging__prev.disable,
.paging .paging__next.disable { opacity: .4; pointer-events: none; }
/* Tablet Portrait */
@media (max-width: 1023px) {
.paging { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
}

@media (max-width: 1140px) {
  .paging li a { font-size: 14px; width: 30px; height: 30px; line-height: 30px; font-size: 12px; }
  }
/* Mobile */
@media (max-width: 767px) {
.paging li + li { margin-left: 5px; }
.paging .paging__spacer { margin: 0 0 0 5px; }
.paging .paging__prev span + span,
.paging .paging__next span + span { margin-left: 0; }
.paging .paging__prev span,
.paging .paging__next span { width: 11px; height: 8px; }
.paging .paging__prev { margin-right: 5px; }
.paging .paging__next { margin-left: 5px; }
.paging .paging__next,
.paging .paging__prev { padding: 4px 6px 6px; }
.paging li a { font-size: 14px; width: 30px; height: 30px; line-height: 30px; font-size: 12px; }

}

 /* ------------------------------------------------------------ *\
	Settings
\* ------------------------------------------------------------ */
  /* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */
.ico-arrow-down-dark { background-image: url(../images/sprite.png); background-position: 65.77540106951871% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-down { background-image: url(../images/sprite.png); background-position: 69.5187165775401% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-left { background-image: url(../images/sprite.png); background-position: 52.44565217391305% 28.26086956521739%; background-size: 2400% 1250%; width: 16px; height: 12px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-right { background-image: url(../images/sprite.png); background-position: 57.88043478260869% 28.26086956521739%; background-size: 2400% 1250%; width: 16px; height: 12px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-up { background-image: url(../images/sprite.png); background-position: 73.2620320855615% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-check { background-image: url(../images/sprite.png); background-position: 62.133333333333326% 27.27272727272727%; background-size: 4266.666666666666% 2142.8571428571427%; width: 9px; height: 7px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-pie { background-image: url(../images/sprite.png); background-position: 47.2972972972973% 28.676470588235293%; background-size: 2742.8571428571427% 1071.4285714285713%; width: 14px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-search { background-image: url(../images/sprite.png); background-position: 41.96185286103542% 29.32330827067669%; background-size: 2258.823529411765% 882.3529411764706%; width: 17px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0px; }
.logo-image { background-image: url(../images/sprite.png); background-position: 0% 0%; background-size: 256% 100%; width: 150px; height: 150px; display: inline-block; vertical-align: middle; font-size: 0px; }
.logo-text { background-image: url(../images/sprite.png); background-position: 100% 0%; background-size: 166.95652173913044% 428.57142857142856%; width: 230px; height: 35px; display: inline-block; vertical-align: middle; font-size: 0px; }
@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 192dpi) {
.ico-arrow-down-dark { background-image: url(../images/sprite.@2x.png); background-position: 65.77540106951871% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-down { background-image: url(../images/sprite.@2x.png); background-position: 69.5187165775401% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-left { background-image: url(../images/sprite.@2x.png); background-position: 52.44565217391305% 28.26086956521739%; background-size: 2400% 1250%; width: 16px; height: 12px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-right { background-image: url(../images/sprite.@2x.png); background-position: 57.88043478260869% 28.26086956521739%; background-size: 2400% 1250%; width: 16px; height: 12px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-arrow-up { background-image: url(../images/sprite.@2x.png); background-position: 73.2620320855615% 27.083333333333332%; background-size: 3840% 2500%; width: 10px; height: 6px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-check { background-image: url(../images/sprite.@2x.png); background-position: 62.133333333333326% 27.27272727272727%; background-size: 4266.666666666666% 2142.8571428571427%; width: 9px; height: 7px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-pie { background-image: url(../images/sprite.@2x.png); background-position: 47.2972972972973% 28.676470588235293%; background-size: 2742.8571428571427% 1071.4285714285713%; width: 14px; height: 14px; display: inline-block; vertical-align: middle; font-size: 0px; }
.ico-search { background-image: url(../images/sprite.@2x.png); background-position: 41.96185286103542% 29.32330827067669%; background-size: 2258.823529411765% 882.3529411764706%; width: 17px; height: 17px; display: inline-block; vertical-align: middle; font-size: 0px; }
.logo-image { background-image: url(../images/sprite.@2x.png); background-position: 0% 0%; background-size: 256% 100%; width: 150px; height: 150px; display: inline-block; vertical-align: middle; font-size: 0px; }
.logo-text { background-image: url(../images/sprite.@2x.png); background-position: 100% 0%; background-size: 166.95652173913044% 428.57142857142856%; width: 230px; height: 35px; display: inline-block; vertical-align: middle; font-size: 0px; }
}

.committeeMarkup.ui.modal.transition {
  height: 328px;
}

.table-label-text {
  font-weight: 700;
}

.disqus_container {
  max-width: 1140px;
  margin: 0 auto;
}

.social-bar {
  max-width: 1140px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  padding:0 20px;
}
.right-floated-social-button {
  float: right;

}

.right-floated-social-button.subscribe-btn {
    color: white;
    background-color: #ee9e27;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

.right-floated-social-button.subscribe-btn:hover,
.right-floated-social-button.subscribe-btn:active {
    color: white;
    background-color: #fe9800;
    border: 2px solid#F7E3D4;
}

.subscribe-modal {
  height:565px;
}
.content {
  height: 100%;
}

.ui.modal .scrolling.content {
  max-height: calc(100vh);
  overflow: auto;
}

.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus {
  color: rgba(0,0,0,.95);
  border-color: #85b7d9;
  border-radius: .28571429rem;
  background: #fff;
  box-shadow: 0px 0px 2px 2px rgba(16, 123, 230, 0.24);
}

.ui.labeled.input>.label:not(.corner) {
  padding-top: 1.1em;
}
.ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {
  top: 1.1em;
}

.main-container {
  padding-top: 45px;
  padding-bottom: 76px;
  
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  zoom: 1
}

.committee-label {
  margin-bottom: 0px;
    font-size: 1.0em;
    color: grey;
    border: none;
    min-height: 48px;
}

.btn.btn--blue.btn--sort:hover {
  color:white;
}

.btn.btn--blue.btn--sort:focus {
  color:white;
}

/* back to home button */
.checkbox.back-home-div {
  margin-bottom: 0px;
    margin-top: 10px;
    width: 15%;
    float: right;
    margin-right: 10px;
    min-height: 38px;
    text-align: center;
    padding-top: 12px;
}

.back-home-a {
  color:white;
  text-decoration: none;
}

@media (max-width:767px) {
  .back-home-div {
    width: 47%;
  }
}

/* back to home on iPhone 6/7/8 Plus*/
@media (max-width:414px) {
  .checkbox.back-home-div {
    width: 48.6% !important;
    height: 44px !important;
    margin-right: 5px;
  }
}

/* back to home on iPhone 6/7/8 */
@media (max-width:375px) {
  .checkbox.back-home-div {
    width: 48.5% !important;
    height: 44px !important;
    margin-right: 5px;
  }
}

/* back to home on iPhone 5 */
@media (max-width:321px) {
  .checkbox.back-home-div {
    width: 48.2% !important;
    height: 44px !important;
    margin-right: 5px;
  }
}

.high-lighted {
  color: unset;
}

.refined-by-checkbox {
  margin-top: 10px;
  margin-bottom: 0px;
}

.skip-to-main-div {
  position: absolute;
  top: 60px;
  left:0;
  background-color: transparent;
  color:transparent;  
  width: 0;
  height: 0;
  overflow: hidden;
  border: none;
}
.skip-to-main-div:focus-within {
  left:10px;
  width: 200px;
  height: 20px;
  background-color: white;
  color:black;
  
}

.skip-to-main-a {
  color: #000000;
  border: none;
}
.skip-to-main-a:focus {
  color: #000000;
  border: 2px solid #ff0;
}

#search-button {
  border-radius: 0 5px 5px 0;
}
.error-page-heading {
  font-family: Impact;
  font-weight: 500;
}
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input:focus {
  border-top-color: white;
  border-bottom-color: white;
}

.ui.header {
  background: transparent;
}
.file.pdf.outline.icon {
  color:red;
}

.pdf-container {
  min-height: 800px;
  width:724px;
}

@media (max-width: 767px) {
  .pdf-container {
    min-height: 800px;
    width:95%;
    margin-top: 15px;
  } 
}

.react-pdf__Document {
  border:none;
  overflow: auto;
}

.pdf-inner-container {
  width:100%;
  min-height: 940px;
  border:1px solid black;
}

.pdf-funcion-button {
  background-color: hsla(0,0%,0%,.12);
  background-image: linear-gradient(hsla(0,0%,100%,.05), hsla(0,0%,100%,0));
  background-clip: padding-box;
  border: 1px solid hsla(0,0%,0%,.35);
  border-color: hsla(0,0%,0%,.32) hsla(0,0%,0%,.38) hsla(0,0%,0%,.42);
  box-shadow: 0 1px 0 hsla(0,0%,100%,.05) inset, 0 0 1px hsla(0,0%,100%,.15) inset, 0 1px 0 hsla(0,0%,100%,.05);
  -webkit-transition-property: background-color, border-color, box-shadow;
  -webkit-transition-duration: 150ms;
  -webkit-transition-timing-function: ease;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease;
  min-width: 16px;
  padding: 2px 6px 0;
  width: auto;
  border-radius: 2px;
  color: hsla(0,0%,100%,.8);
  font-size: 12px;
  line-height: 14px;
  margin: 4px 2px 4px 0;
  float: left;
  height:20px;
}

.pdf-function-page-text {
  margin: 6px 3px 6px 2px;
    font-family: sans-serif;
    font-size: 12px;
    padding: 2px;
    text-align: center;
    letter-spacing: normal;
    word-spacing: normal;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    font-weight: normal;
    margin-top: 6px;
    display: inline-block;
    color: lightgray;
}
.pdf-function-bar {
  display: flex;
  justify-content: left;
  vertical-align: middle;
  padding: 0px;
  border:none;
  background-color: #595959;
  height: 28px;
  z-index: 10;
}



.hide-when-normal {
  display:none;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}
.react-datepicker-popper {
  z-index: 10 !important;
}

.list-desc {
  margin: 10px;
}

.ui.dropdown:not(.button)>.default.text {
  color: black;
}

/* .status_badge_1 { background-color: #154BAB; color: #fff;}
.status_badge_2 {background-color: #81e0e2; color: #fff;}
.status_badge_3 {background-color: #96ec74; color: #fff;}
.status_badge_4 {background-color: #3fd8b1; color: #fff;}
.status_badge_5 {background-color: #439F21; color: #fff;}
.status_badge_6 {background-color: #6F65A1; color: #fff;}
.status_badge_7 {background-color: #DB3505; color: #fff;}
.status_badge_8 {background-color: #E22C3E; color: #fff;}
.status_badge_9 {background-color: #DF0305; color: #fff;}
.status_badge_10 {background-color: #682F71; color: #fff;}
.status_badge_11 {background-color: #DF0305; color: #fff;}
.status_badge_12 {background-color: #DF0305; color: #fff;}
.status_badge_13 {background-color: #C0551B; color: #fff;}
.status_badge_14 {background-color: #0E96A2; color: #fff;}
.status_badge_15 {background-color: #389823; color: #fff;}
.status_badge_16 {background-color: #ccaefe; color: #fff;}
.status_badge_17 {background-color: #56af45; color: #fff;}
.status_badge_18 {background-color: #DF0305; color: #fff;}
.status_badge_19 {background-color: #ee9e27; color: #fff;} */


.status_badge_1 { background-color: #154BAB; color: #fff; white-space: nowrap;}
.status_badge_2 {background-color: #81e0e2; color: #fff; white-space: nowrap;}
.status_badge_3 {background-color: #338712; color: #fff; white-space: nowrap;}
.status_badge_4 {background-color: #3fd8b1; color: #fff; white-space: nowrap;}
.status_badge_5 {background-color: #39871D; color: #fff; white-space: nowrap;}
.status_badge_6 {background-color: #6F65A1; color: #fff; white-space: nowrap;}
.status_badge_7 {background-color: #DB3505; color: #fff; white-space: nowrap;}
.status_badge_8 {background-color: #338712; color: #fff; white-space: nowrap;}
.status_badge_9 {background-color: #DF0305; color: #fff; white-space: nowrap;}
.status_badge_10 {background-color: #682F71; color: #fff; white-space: nowrap;}
.status_badge_11 {background-color: #DF0305; color: #fff; white-space: nowrap;}
.status_badge_12 {background-color: #DF0305; color: #fff; white-space: nowrap;}
.status_badge_13 {background-color: #C0551B; color: #fff; white-space: nowrap;}
.status_badge_14 {background-color: #0B838E; color: #fff; white-space: nowrap;}
.status_badge_15 {background-color: #39871D; color: #fff; white-space: nowrap;}
.status_badge_16 {background-color: #ccaefe; color: #fff; white-space: nowrap;}
.status_badge_17 {background-color: #418334; color: #fff; white-space: nowrap;}
.status_badge_18 {background-color: #DF0305; color: #fff; white-space: nowrap;}
.status_badge_19 {background-color: #ee9e27; color: #fff; white-space: nowrap;}
.status_badge_20 { display: none !important;background-color: #b00655; color: #fff; white-space: nowrap;}
.status_badge_21 {background-color: #757575; color: #fff; white-space: nowrap;}
.status_badge_22 {background-color: #757575; color: #fff; white-space: nowrap;}
.status_badge_99 {display: none !important; background-color: #68dcc6; color: #fff; white-space: nowrap;}

.additional-info-text {
  color: balck;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.additional-info-text.mobile {
  color: balck;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
}
/* .section-details .posts {background-color: red !important;} */
html, body {width: auto!important; overflow-x: hidden!important} 

.introducer_status_tag {
  font-size: 90%;
  border-radius: 5px;
  padding: 2px 4px;
}
.ui.list .list>.item>.content, .ui.list>.item>.content {
  line-height: 1.4;
}

.vote_info_popup {
  font-size: 80%;
}

.ui.segment.active.tab {
  display: block; 
    transition: transform .5s, opacity .5s;
    opacity: 1.0;
    transform: scale(1.0);
}

#st-2 .st-btn[data-network='twitter'] {
  background-color:#147AC8 !important;
}
#st-2 .st-btn[data-network='email'] {
  background-color:#757575 !important;
}

#search_legislation_input_label {
  visibility: hidden !important;
  color:black !important;
  background-color: white !important;
}

div.sticky {
  position: -webkit- fixed;
  position: fixed;
  top: 167px;
  left: 0px;
  background-color: transparent;
  width: 36px;
  height: 140px;
  z-index: 200;
}

.sticky_detail_page {
  position: -webkit-fixed;
  position: fixed;
  top: 307px;
  left: 0px;
  background-color: transparent;
  width: 36px;
  height: 33px;
  z-index: 200;
}
.sticky_detail_page > .st-custom-button {
    background-color: #ee9e27;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    position: relative;
    border-radius: 0;
    transition: width 0.5s ease-in-out;
}
.sticky_detail_page>.st-custom-button:hover {
  width: 120px;
  border-radius: 0;
}
.sticky_detail_page>.st-custom-button>span {
  color: transparent;
  transition: color 0.5s ease-in-out;
  position: absolute;
  overflow: visible;
  white-space: nowrap;
  right: 10px;
  top: 10px;
}
.sticky_detail_page>.st-custom-button:hover>span{
  display: inline;
  color: #fff;
  /*color: black;*/
}
.sticky_detail_page>.st-custom-button>i{
  position: absolute;
  left: 8px;
  top: 10px;
}

 .ui.list>.item>i.icon {
  vertical-align: middle;
  
}

.st-custom-button[data-network] {
  background-color: #55acee;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  position: relative;
  transition: width 0.5s ease-in-out;
}

.st-custom-button[data-network=twitter] {
  background-color: #55acee;
  border-radius: 0;
}
.st-custom-button[data-network=facebook] {
  background-color: #4267B2;
}
.st-custom-button[data-network=email] {
  background-color: #7d7d7d;
}
.st-custom-button[data-network=linkedin] {
  background-color: #0077b5;
  border-radius: 0;
}

.st-custom-button[data-network]:hover {
  width: 100px;
  border-radius: 0;
}

.st-custom-button[data-network]>i{
  position: absolute;
  left: 8px;
  top: 10px;
}

.st-custom-button[data-network]>span {
  color: transparent;
  transition: color 0.5s ease-in-out;
  position: absolute;
  overflow: visible;
  white-space: nowrap;
  right: 10px;
  top: 10px;
}
.st-custom-button[data-network]:hover>span{
  display: inline;
  color: white;
}

.st-custom-button[data-network]:hover, .st-custom-button[data-network]:focus {
  text-decoration: underline;
}

@media (max-width: 767px) {
  div.sticky {
    position: -webkit- fixed;
    position: fixed;
    top: auto;
    left: auto;
    bottom: 0px;
    right:0px;
    background-color: white;
    width: 100%;
    height: 35px;
    z-index: 200;
  }
  
  .st-custom-button[data-network] {
    width: 25%;
    height: 35px;
    cursor: pointer;
    font-weight: bold;
    z-index: 201;
    position: relative;
    display: inline-block;
    transition: opacity 0.5s ease-in-out;
  }
  
  .st-custom-button[data-network]:hover, .st-custom-button[data-network]:focus {
    text-decoration: underline;
  }
  
  
  .st-custom-button[data-network=twitter] {
    background-color: #55acee;
    border-radius: 0px;
  }
  .st-custom-button[data-network=facebook] {
    background-color: #4267B2;
  }
  .st-custom-button[data-network=email] {
    background-color: #7d7d7d;
  }
  .st-custom-button[data-network=linkedin] {
    background-color: #0077b5;
    border-radius: 0px;
  }
  .st-custom-button[data-network]:hover {
    width: 25%;
    border-radius: 0px;
  }
  
  .st-custom-button[data-network]>i{
    position: absolute;
    left: 8px;
    top: 10px;
  }
  
  .st-custom-button[data-network]>span {
    color: transparent;
    transition: color 0.5s ease-in-out;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    left: 30px;
    top: 10px;
  }
  .st-custom-button[data-network]:hover>span{
    display: inline;
    color: white;
  }
}


.hide {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.personal-info-item {
  margin-top: 10px;
  margin-left: 25px;
}
.ui.scrolling.modal.transition.visible.active {
  min-height: 100vh;
}
.ui.modal .scrolling.content {
  max-height: calc(90vh);
}

.additional_info {
  list-style-type:none;
  line-height: 2.0;
}

.search-box-tooltip {
  position: absolute;
  top: 60px;
  left: 330px;
  padding: 12px !important;
  padding-right: 7px !important;
  background-color: transparent !important;
}

@media (max-width: 850px) {
  .search-box-tooltip {
    display: none;
    position: absolute;
    top: 81px;
    left: 0px;
    height: 40px;
  } 
}

@media (max-width: 767px) {
  .search-box-tooltip {
    display: inline-block;
    position: absolute;
    top: 81px;
    left: 0px;
    height: 40px;
  } 
}
.search-box-tooltip>a:hover {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}

.search-box-tooltip>i {
  position: absolute;
  top: 5px;
  left: 5px;
  color:orangered !important;
}

.content.active {
  padding-left: 1.68rem !important;
}
.help-list {
  line-height: 2.0;
  padding-left: 1.2rem !important;
}

.help-small-list .ui.list .list>.item>.content, .ui.list>.item>.content {
  line-height: 1.8;
}

/* Print Only CSS  Test only*/
.print_only {
  display: none !important;
}

.post__date>p>a {
  text-decoration: none;
  font-size: 1.1rem;
  color: #1576d1;
}
.post__date>p>a:hover {
  text-decoration: underline;
}

.additional_info_label>p>span {
  font-weight: 600;
  font-family: Proxima Nova;
  font-size: 1.1;
}
.api_help_document>p {
  line-height: 1.8;
  margin-bottom: 1.0rem;
}

.api_help_document {
  padding-bottom: 72px;
}

/* @media (min-width:100px) { */
@media print {
  * {
    color:black !important;
    background-color: white !important;
    background-image: none !important;
    border: none !important;
    overflow:visible !important;
  }
  body {
    height: auto !important;
  }


  a[href]:after {
    content: none !important;
  }
  
  .no_print {
    display: none !important;
  }
  .print_only {
    display: block !important;
  }
  body {
    width:100% !important;
  }
  .intro__head {
    margin-bottom: 10px !important;
  }

  .section__head {
    display: block !important;
  }

  .intro__tag {
    display: none !important;
  }

  .council_period_header {
    margin-left: 1rem !important;
    position: relative !important;
  }
  .section__body.print_only {
    margin-left: 1rem !important;
  }
  .section.section--inkblue.number_displayer {
    padding-bottom: 0 !important;
  }
  .main {
    padding: 0 !important;
  }
  .footer {
    padding: 0 0 75px !important;
    position: relative !important;
    z-index: 9999 !important;
  }
  .footer .footer__nav ul {
    display: block;
  }
  .footer .footer__nav li {
    padding: 0 !important;
    line-height: 2.0;
  }
  .socials a {
    width:100% !important;
    text-decoration:none !important;
    text-align: left !important;
  }
  .footer_print_header {
    page-break-before: always !important;
  }
  .filters {
      justify-content: space-between;
      align-items: end !important;
  }
  .post__content>.post__date {
    display: block;
    float: none;
  }
  .post__date.additional_info_label {
    display: block;
  }
  .intro.intro--post {
    padding-bottom: 0 !important;
  }
  .section__paging {
    padding-top:15px !important;
  }
  .posts .post--alt { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: normal; padding: 0; margin-bottom: 0; }
  .posts .post--alt + .post--alt { margin-top: 25px; padding-top: 25px; border-top: 1px solid #e5e5e5; }
  .posts .post--alt + .post--alt.summary {margin-top: 10px; padding-top: 10px;border-top: none;}
  .post--alt .post__date { min-width: 14%; }
  .post--alt .post__content { width: 86%; padding-left:3.0em;}
  .post--alt .post__content .h5 { font-weight: 400; }
  .post--alt .post__content a { color: #1473CC; margin-top: 10px; }
}

@media (max-width:767px) {
  .ui.mini.fluid.vertical.steps {
    display: none;
  }
  .intro.intro--post {
    padding-bottom: 10px !important;
  }
  .intro__foot {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* pdf viewer*/
.ui.button.pdf_viewer_enable_button {
  width:200px;
  height: 40px;
  margin-bottom: 15px;
  margin-left: 1rem;
}
.list-links {
  padding-left: 1rem;
}
.posts {
  padding-left: 1rem;
}

.item.personal-info-item>i, .item.personal-info-item-sm>i {
  vertical-align: middle !important;
}

.status-box {
  position: absolute;
  white-space: nowrap;
  top: -6px;
  right:0px;
}
@media (max-width:1023px) {
  .status-box {
    top: -6px;
  right:15px;
  }
  
}

@media (max-width:767px) {
  .status-box {
    top: -6px;
  right:10px;
  }
  
}

#app-refresh-button {
  background-color: rgb(138, 228, 203);
  color: white;
  border: none;
  width: 180px;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
 }
#app-refresh-button:hover {
  background-color: #21BA45;
}

#app-refresh-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;

}
#app-refresh-close-icon:hover {
  cursor: pointer;
}

.ml-2 {
  margin-left: 0.25rem;
}